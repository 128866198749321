export const categoryTypes = {
    'en': [{
        title: 'Any',
        value: 'all'
    },
    {
        title: 'Condo',
        value: 'condo'
    },
    {
        title: 'House',
        value: 'house'
    },
    {
        title: 'Land',
        value: 'land'
    },
    {
        title: 'Commercial',
        value: 'commercial'
    }],
    'th': [{
        title: 'ประเภท',
        value: 'all'
    },
    {
        title: 'คอนโด',
        value: 'condo'
    },
    {
        title: 'บ้าน',
        value: 'house'
    },
    {
        title: 'ที่ดิน',
        value: 'land'
    },
    {
        title: 'เชิงพาณิชย์',
        value: 'commercial'
    }],    
    'cn': [{
        title: '不限',
        value: 'all'
    },
    {
        title: '公寓',
        value: 'condo'
    },
    {
        title: '别墅',
        value: 'house'
    },
    {
        title: '土地',
        value: 'land'
    },
    {
        title: '商业',
        value: 'commercial'
    }],
}